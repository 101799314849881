import React, { lazy } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import AuthGuard from "../components/auth";
import ChangePassword from "../pages/ChangePassword";
import PageLayout from "../pages/Layout";
import UserManagement from "../pages/UserManagement";


const Login = lazy(() => import("../pages/Login"));
const View = lazy(() => import("../pages/UserManagement/View"));


const Routing = () => {
  return (
    <Routes>

      <Route path="/" element={<Login />} />
      <Route path="/changepassword" element={< ChangePassword />} />
      <Route path="/"
        element={<AuthGuard> <PageLayout /> </AuthGuard>
        }>

        <Route path="/user-management" element={<UserManagement />} />
        <Route path="/user-management/view/:id" element={< View />} />

      </Route>
      <Route path="*" element={<Navigate replace to="/" />} />

    </Routes>
  );
};

export default Routing;
