import React, { useEffect, useState } from 'react'
import { Avatar, Col, Dropdown, Layout, Menu, Row, Space } from 'antd'
import { LayoutCollapsed, LayoutUnCollapsed, Union, ProfileDown, ChangePassword, Logout } from '../../svg'
import { Link, Outlet } from 'react-router-dom';
import { toAbsoluteUrl } from '../../utils'
import './Layout.scss'
import { doLogout } from '../../Redux/AuthSlice/action';
import store from '../../Redux/store'


const { Header, Content, Sider } = Layout;


const items = [
	{
		key: '1',	
		label: (
			<Link to="/changepassword">
				Change password
			</Link>
		),
		icon: <ChangePassword />,
	},
	{
		key: '2',
		label: (
			<Link onClick={() => store.dispatch(doLogout())} href="/login" to="/login">
				Logout
			</Link>
		),
		icon: <Logout />,
	},

];


const PageLayout = (props) => {

	const [collapsed, setCollapsed] = useState(false);
	const [pagename, setPagename] = useState(null);
	const [menukey, setMenuKey] = useState();
	const localekey = localStorage.getItem('menukey')



	const handleMenuKey = (key) => {
		setMenuKey(key);
		localStorage.setItem('menukey', key)
	}

	useEffect(() => {
		if (localekey) {
			setMenuKey(localekey);
		} else {
			setMenuKey('1');
			localStorage.setItem('menukey', '1')
		}
	}, [localekey])

	useEffect(() => {
		setPagename("Users")

	}, [])

	function getItem(label, key, icon, children) {
		return {
			key,
			icon,
			children,
			label,
		};
	}

	const itemlist = [
		getItem('User Management', '1', <Link to="/user-management" onClick={() => handleMenuKey('1')}><Union /></Link>),

	];

	return (
		<Layout>
			<Sider
				collapsible
				width="270px"
				collapsed={collapsed}
				onCollapse={(value) => setCollapsed(value)}
				trigger={collapsed === true ? <LayoutCollapsed /> : <LayoutUnCollapsed />}
				reverseArrow={true}
				breakpoint="md"
			>
				<figure className='layout-logo'>
					<img className='logo' src={toAbsoluteUrl('/images/AIVideoConsultBLUE_1.svg')} alt="logo" />
				</figure>
				<Menu selectedKeys={[menukey]} mode="inline" items={itemlist} />
			</Sider>
			<Layout className="site-layout">
				<Header className='layout-header'>
					<Row gutter={16}>
						<Col className="gutter-row d-flex-center-end mt-md-20" xs={24}>
							{/* <Button onClick={() => navigate('/notifications')}><Notification /></Button> */}

							<Dropdown menu={{ items }} trigger={['click']} onClick={(e) => e.preventDefault()}>
								<Space>
									<Avatar
										size="large"
										src={toAbsoluteUrl('/images/Avtar.png')}
										className='profile-avtar'
									/>
									<ProfileDown />
								</Space>

							</Dropdown>
						</Col>
					</Row>
				</Header>
				<Header className='breadcrumb-header'>
					<Row gutter={16}>
						<Col className="gutter-row d-flex-center-start" sm={24} md={12}>
							{/* <h2 className='header-title'>Active Care Program management</h2> */}
							<h2 className='header-title'>{pagename}</h2>
						</Col>
						<Col className="gutter-row d-flex-center-end sm-flex-row-reverse" sm={24} md={12}>
							{/* <Breadcrumb className='cls-breadcrum' separator=">">
								<item>Home</item>
								<item href="/user-management">User Management</item>
							</Breadcrumb> */}
						</Col>
					</Row>
				</Header>
				<Content>
					<div className="content-body">
						<div className="content-wrap" ref={props.setContainer}>
							<Outlet />
						</div>
					</div>
				</Content>
			</Layout>
		</Layout>
	);
};

export default PageLayout;
