import { LS_AUTHTOKEN, LS_USER } from "../constants";
import { doLogout } from "../Redux/AuthSlice/action";


//To concate the path for the public folder
export const toAbsoluteUrl = pathname => (process.env.PUBLIC_URL + pathname)

export const setupAxios = (axios, store) => {

  let token = JSON.parse(localStorage.getItem(LS_AUTHTOKEN));
  let userData = JSON.parse(localStorage.getItem(LS_USER));

  if (token) {
    store.dispatch({ type: "LOGIN_SUCCESS", payload: { data: userData } });
  } else {
    store.dispatch({ type: "LOGIN_FAIL", payload: {} });
  }

  axios.interceptors.request.use(
    (req) => {
      const {
        auth: { userData },
      } = store.getState();

      if (userData && userData.accessToken) {
        req.headers["Authorization"] = `${userData.accessToken}`;
      }

      return req;
    },
    (err) => Promise.reject(err)
  );



  axios.interceptors.response.use(null, (err) => {
    if (err.response) {
      if (err.response.status === 403) {
        store.dispatch(doLogout())

        return Promise.reject(err);
      } else return Promise.reject(err);
    } else if (err.request) {
      return Promise.reject({
        response: {
          data: {
            message: "Something went wrong, Please try again later!!!"
          }
        }
      });
    }
  });
}


export const renderTagColor = (status) => {
  if (status === 'Active' || status === 'No' || status === true || status === 'Complete' || status === 'Mild') {
    return 'green'
  } else if (status === 'Inactive' || status === false || status === 'Inactive-Default' || status === 'Yes' || status === 'Inactive-Unenrolled' || status === 'Open' || status === 'Severe' || status === '  ') {
    return 'red'
  } else if (status === 'Moderate') {
    return 'yellow'
  }
  else {
    return
  }
}