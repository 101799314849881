// ENV CONSTANTS
export const { REACT_APP_API_BASE: API_BASE } = process.env;
export const { REACT_APP_NAME: APP_NAME } = process.env;

// LOCALSTORAGE KEYNAME

export const LS_USER = `user ${APP_NAME}`
export const LS_AUTHTOKEN = `authToken ${APP_NAME}`

// API ENDPOINTS

export const API_LOGIN = `${API_BASE}v1/user/admin-login`;
export const API_LOGOUT = `${API_BASE}v1/user/logout`

//API FOR CHANGE PASSWORD

export const API_CHANGE_PASSWORD = `${API_BASE}v1/user/adminChangePassword`;

//API FOR USER LIST

export const API_LIST_USER = `${API_BASE}v1/user/getListOfUser`;

//API FOR USER DETAILS

export const API_USER_DETAIL = `${API_BASE}v1/user/getUserDetails/`;

//API FOR DELETE USER

export const API_DELETE_USER = `${API_BASE}v1/user/deleteUser/`;
