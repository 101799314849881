import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { LS_AUTHTOKEN } from "../../constants";

const AuthGuard = ({ children }) => {
  const navigate = useNavigate();
  const isLoggedIn = localStorage.getItem(LS_AUTHTOKEN);


  useEffect(() => {
    if (!isLoggedIn) navigate('/login', { replace: true });
  }, [isLoggedIn, navigate]);

  return <>{children}</>;
};

export default AuthGuard;
