import { createSlice } from "@reduxjs/toolkit";
import { API_LIST_USER, USER_LIST_F, USER_LIST_S } from "../constants";
import { API_DELETE_USER, API_USER_DETAIL } from "../constants/api";
import { DELETE_USER_F, DELETE_USER_S, GET_USER_DETAILS_F, GET_USER_DETAILS_S } from "../constants/types";

const initialState = {
  userList: [],
  userInfo: {},
};

export const getUserList = (data) => ({
  type: "API",
  payload: {
    url: API_LIST_USER,
    method: "POST",
    data: data,
    hideLoader: false,
    success: (data) => ({
      type: USER_LIST_S,
      payload: data,
    }),
    error: (data) => ({
      type: USER_LIST_F,
      payload: [],
    }),
  },
});

export const getUserDetails = (id) => ({
  type: "API",
  payload: {
    url: API_USER_DETAIL + `${id}`,
    method: "GET",
    hideLoader: false,
    success: (data) => ({
      type: GET_USER_DETAILS_S,
      payload: data,
    }),
    error: (data) => ({
      type: GET_USER_DETAILS_F,
      payload: [],
    }),
  },
})

export const deleteUser = (id) => ({
  type: "API",
  payload: {
    url: API_DELETE_USER + `${id}`,
    method: "DELETE",
    hideLoader: false,
    success: (data) => ({
      type: DELETE_USER_S,
      payload: data,
    }),
    error: (data) => ({
      type: DELETE_USER_F,
      payload: [],
    }),
  },
})

// Reducer
const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  extraReducers: (builder) => {
    builder.addCase(USER_LIST_S, (state, action) => {
      state.userList = action.payload.data;
    });
    builder.addCase(USER_LIST_F, (state, action) => {
      state.userList = [];
    });

    builder.addCase(GET_USER_DETAILS_S, (state, action) => {
      state.userInfo = action.payload.data;
    });
    builder.addCase(GET_USER_DETAILS_F, (state, action) => {
      state.userInfo = {};
    });

    builder.addCase(DELETE_USER_S, (state, action) => {
      state.userList = action.payload.data;
    });
    builder.addCase(DELETE_USER_F, (state, action) => {
      state.userList = [];
    });
  },
});

export const { loaderChange } = userSlice.actions;
export default userSlice.reducer;
