
import { API_CHANGE_PASSWORD, API_LOGIN, } from "../../constants/api";
import { LOGIN_F, LOGIN_S, LOGOUT } from "../../constants/types";

export const loginAction = (data) => ({
    type: "API",
    payload: {
        url: API_LOGIN,
        method: "POST",
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: LOGIN_S,
            payload: data,
        }),
        error: (data) => ({
            type: LOGIN_F,
            payload: {},
        }),
    },
});

export const doLogout = (data) => ({
    type: LOGOUT
})

export const changePasswordAction = (data) => ({
    type: "API",
    payload: {
        url: API_CHANGE_PASSWORD,
        method: "POST",
        data: data,
        hideLoader: false,
        // success: (data) => ({
        //     type: CHANGE_PASSWORD_S,
        //     payload: data,
        // }),
        // error: (data) => ({
        //     type: CHANGE_PASSWORD_F,
        //     payload: data
        // })
    },
});