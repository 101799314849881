import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { LS_AUTHTOKEN, LS_USER } from "../../constants/api";
import { LOGIN_F, LOGIN_S, LOGOUT } from "../../constants/types";


const initialState = {
    // Global loader for api 
    isLoading: false,
    // Auth Data
    isLoggedIn: false,
    userData: {},
};



// Reducer
const loginSlice = createSlice({
    name: "login",
    initialState: initialState,
    reducers: {
        loaderChange: (state, payload) => {
            state.isLoading = payload.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(LOGIN_S, (state, action) => {
            // Default header for auth
            axios.defaults.headers.common["Authorization"] = `Bearer ${action.payload.data.accessToken}`;
            localStorage.setItem(LS_AUTHTOKEN, JSON.stringify(action.payload.data.accessToken));
            localStorage.setItem(LS_USER, JSON.stringify(action.payload.data));
            localStorage.setItem("user_id", JSON.stringify(action.payload.data._id));


            state.userData = action.payload;
            state.isLoggedIn = true;
        });
        builder.addCase(LOGIN_F, (state, action) => {
            // remove items on logout
            delete axios.defaults.headers.common['Authorization']
            localStorage.removeItem(LS_AUTHTOKEN);
            localStorage.removeItem(LS_USER);

            state.userData = {};
            state.isLoggedIn = false;
        });
        builder.addCase(LOGOUT, (state, action) => {
            delete axios.defaults.headers.common['Authorization']
            localStorage.removeItem(LS_AUTHTOKEN);
            localStorage.removeItem(LS_USER);

            state.userData = {};
            state.isLoggedIn = false;
        });
    },
});

export const { loaderChange } = loginSlice.actions;
export default loginSlice.reducer;
