import React, { useEffect, useState } from 'react'
import { Table, Button, Space, Modal, message } from 'antd'
import { useNavigate } from 'react-router-dom';
import './UserManagement.scss';
import { useDispatch } from 'react-redux';
import { deleteUser, getUserList } from '../../Redux/UserSlice';

const UserManagement = () => {


	const navigate = useNavigate();
	const dispatch = useDispatch()

	const [userData, setUserData] = useState([])
	const [args, setArgs] = useState({
		limit: 10,
		skip: 0
	})

	useEffect(() => {
		show()
	}, [args])

	const show = () => {
		const data = {
			limit: args.limit,
			skip: args.skip
		}
		dispatch(getUserList(data))
			.then(res => {
				setUserData(res.data)
			})

	}

	const [showModal, setShowModal] = useState(false);
	const [deleteData, setDeletedata] = useState();

	const showDeleteModal = (item, id) => {
		setShowModal(true);
		setDeletedata(id)

	};

	const handleOk = () => {
		dispatch(deleteUser(deleteData))
			.then(res => message.success(res.message, 1.5))
		setShowModal(false)
		show()
	};

	const handleCancel = () => {
		setShowModal(false);
	};


	const columns = [
		{
			title: 'First name',
			dataIndex: 'firstName',
			key: 'firstname',
		},
		{
			title: 'Last name',
			dataIndex: 'lastName',
			key: 'lastname',
		},
		{
			title: 'Email',
			dataIndex: 'email',
			key: 'email',
		},
		{
			title: 'Mobile number',
			dataIndex: 'phoneNumber',
			key: 'number',
			render: (row, list) => <>{"+" + list?.countryCode + " " + list.phoneNumber}</>
		},
		{
			title: 'Action',
			dataIndex: 'action',
			key: 'action',
			width: 100,
			render: (row, list) => <Space size={10}>
				<Button type="primary" htmlType="submit" onClick={() => navigate('/user-management/view/' + list._id)}>View</Button>

				<Button type="primary" htmlType="submit" onClick={(e) => { showDeleteModal(true, list._id) }}>
					Delete
				</Button>
			</Space>
		},
	];
	
	return (
		<>
			{/* <TableRow /> */}
			<Table
				columns={columns}
				dataSource={userData?.list || []}
				rowKey={(record) => record._id}
				pagination={{
					showSizeChanger: false,
					total: userData.total_records

				}}
				onChange={(pages) => {
					const startIndex = pages.current * pages.pageSize - pages.pageSize;
					setArgs({ ...args, skip: startIndex });
				}}
				scroll={{ x: 1200 }}
				className='check-pad-30'
			/>

			<Modal
				open={showModal}
				title="Delete user"
				width={448}
				centered
				className="centered-text"
				onOk={handleOk}
				onCancel={handleCancel}
				footer={null}
			><p>
					Are you sure you want to delete this user?
				</p>

				<Space
					size={10}
					className="justify-content-center w-100 mar-top-30"
				>
					<Button
						type="primary"
						className="min-width-130"
						onClick={handleOk}
						htmlType="submit"
					>
						Yes
					</Button>
					<Button
						type="default"
						className="min-width-130"
						onClick={handleCancel}
						htmlType="submit"
					>
						No
					</Button>
				</Space>


			</Modal>
		</>
	);
};

export default UserManagement;
