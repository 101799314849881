import React from 'react';
import { Button, Col, Form, Input, message, Row } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { toAbsoluteUrl } from '../../utils';
import { Password } from '../../svg';
import '../Login/Login.scss';
import { useDispatch } from 'react-redux';
import { changePasswordAction } from '../../Redux/AuthSlice/action';


const ChangePassword = () => {

	const navigate = useNavigate();
	const dispatch = useDispatch();
	const adminID = JSON.parse(localStorage.getItem("user_id"))
	console.log('adminID: ', adminID);

	const onFinish = (values) => {
		const data = {
			id: adminID,
			currentPassword: values.oldpassoword,
			newPassword: values.newpassword
		}

		console.log("daataaa", data);
		dispatch(changePasswordAction(data))
			.then(res => {
				console.log("resss", res);
				if (res.statusCode === 201) {
					message.success(res.message, 1.5);
					navigate('/login')
				} else {
					message.error(res.message, 1.5);
					navigate('/login')
				}
			}
			)
			.catch(err => message.error(err.message, 1.5))
	}

	return (
		<>
			<div className='login'>
				<div className='login-wrap shadow-paper'>
					<div className="login-logo text-center">
						<Link to='/dashboard'><img src={toAbsoluteUrl('../images/FERMIA-aiConsultLogo.png')} alt="logo" /></Link>
					</div>
					<Form
						className='login-form'
						onFinish={onFinish}
					>
						<h2 className='hedaer-title'>Change password</h2>
						<Row gutter={[20, 20]} className='patient-detail-card'>
							<Col xl={24}>
								<Form.Item name="oldpassoword"
									rules={[
										{
											validator: (_, value) => {
												if (value) {
													if (/^[^\s].+[^\s]$/.test(value)) {
														return Promise.resolve();
													} else {
														return Promise.reject('White space not allow');
													}
												} else {
													return Promise.reject('Old passoword is required');
												}
											}
										}
									]}>
									<Input.Password placeholder="Old Password" prefix={<Password />} />
								</Form.Item>
							</Col>
							<Col xl={24}>
								<Form.Item name="newpassword"
									rules={[
										({ getFieldValue }) => ({
											validator(_, value) {
												if (getFieldValue('oldpassoword') === value) {
													return Promise.reject('Your old password and new password are same');
												} else if (!value || value === '') {
													return Promise.reject('New password is required');
												} else if (/^\S{3,}$/.test(value) && /^.{8,16}$/.test(value)) {
													return Promise.resolve();
												} else {
													return Promise.reject('Password must be 8-16 characters long and not contain white spaces');
												}

											},
										})
									]}>

									<Input.Password placeholder="New Password" prefix={<Password />} />
								</Form.Item>
							</Col>
							<Col xl={24}>
								<Form.Item name="repeatnewpassword"
									rules={[
										{
											required: true,
											message: 'Confirm password is required',
										},
										({ getFieldValue }) => ({
											validator(_, value) {
												if (!value || getFieldValue('newpassword') === value) {
													return Promise.resolve();
												}

												return Promise.reject(new Error('The repeat password that you entered do not match with new password'));
											},
										}),
									]}>

									<Input.Password placeholder="Confirm Password" prefix={<Password />} />
								</Form.Item>
							</Col>
							<Col xl={24}>
								<Form.Item>
									<Button type="primary" htmlType="submit" className='w-100'> Submit</Button>
								</Form.Item>
							</Col>
						</Row>
					</Form>
				</div>
			</div>
		</>
	);
}

export default ChangePassword;