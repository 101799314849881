export const API = 'API'
export const API_REQUEST = 'API_REQUEST'
export const API_SUCCESS = 'API_SUCCESS'
export const API_ERROR = 'API_ERROR'

export const LOGIN_S = 'LOGIN_SUCCESS'
export const LOGIN_F = 'LOGIN_FAIL'
export const LOGOUT = 'LOGOUT'

export const CHANGE_PASSWORD_S = 'CHANGE_PASSWORD_SUCCESS'
export const CHANGE_PASSWORD_F = 'CHANGE_PASSWORD_FAIL'

export const GET_USER_DETAILS_S = 'GET_USER_DETAILS_SUCCESS'
export const GET_USER_DETAILS_F = 'GET_USER_DETAILS_FAIL'

export const DELETE_USER_S = 'DELETE_USER_SUCCESS'
export const DELETE_USER_F = 'DELETE_USER_FAIL'

